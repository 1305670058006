<template>
  <div class="brands-slider" v-if="filteredLogos.length > 0">
    <VueSlickCarousel v-if="slickSettings" v-bind="slickSettings">
      <!-- <div class="slider-title brands-slider-image">
        <span>{{ data.title }}</span>
      </div> -->
      <div
        v-for="(image, index) in filteredLogos"
        :key="`brand-slider-${index}`"
      >
        <nuxt-img
          class="brands-slider-image"
          :src="image.desktop"
          :alt="image.alt"
        />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  defineComponent,
} from '@nuxtjs/composition-api';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default defineComponent({
  name: 'BrandSlider',
  components: {
    VueSlickCarousel,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const slickSettings = ref();

    const filteredLogos = computed(() =>
      props.data?.logos?.length > 0
        ? props.data.logos
            .filter((l) => l?.active && l.image?.desktop)
            .map((logo) => logo.image)
        : []
    );

    onMounted(() => {
      slickSettings.value = {
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 3000,
        autoplaySpeed: 1000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1339,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
        variableWidth: true,
      };
    });

    return {
      slickSettings,
      filteredLogos,
    };
  },
});
</script>

<style lang="scss" scoped>
.brands-slider {
  border-top: 1px solid var(--c-black);
  padding-left: 1.25rem;
  @include to-tablet-max {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  @include from-desktop-min {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .slider-title {
    span {
      @include mobile-h5;
      @include from-desktop-min {
        @include desktop-h5;
      }
    }
  }
  .brands-slider-image {
    max-width: 9.375rem;
    height: 35px;
    @include from-desktop-min {
      max-width: 250px;
      height: 2.8125rem;
    }
  }
}
</style>

<style lang="scss">
.brands-slider {
  .slick-slider,
  .slick-track {
    height: 50px;
  }
  .slick-slide {
    width: auto !important;
    padding-right: 3.125rem;
    padding-left: 3.125rem;
    @include to-tablet-max {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem;
    }
  }
}
</style>
